.chart-wrapper {
    flex: 1;
    overflow: hidden;

    .chart-no-data-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            z-index: 0;
            opacity: 0.4;
            filter: blur(6px);
        }

    }

    .chart {
        height: 100%;
        flex-grow: 1;
    }
}

.chart-tooltip {
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px;
    background-color: var(--color-light);
    max-width: 130px !important;
}

.legend-label {
    cursor: pointer;
    .legend-dot {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border-width: 1px;
        border-style: solid;
        transition: background-color 0.2s linear;
    }

    &:not(.active) {
        text-decoration: line-through !important;
        opacity: 0.6;
        transition: opacity 0.2s linear;

        .legend-dot {
            background-color: transparent !important;
        }
    }
}
