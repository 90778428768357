:root {
  --border-light: 1px solid rgba(var(--color-light-rgb), 0.2);
}

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  &::-webkit-scrollbar {
    height: 5px;
    width: 10px;
    background-color: var(--color-background-lighter);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 8px;
  }

  &.small-scrollbars {
    &::-webkit-scrollbar {
      width: 3px;
      background-color: var(--color-background-lighter);
    } 
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.pointer {
  cursor: pointer;
}

.nxs-link {
  color: var(--color-primary);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.absolute-full-page {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--color-background-lighter) inset !important;
}

.img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}
